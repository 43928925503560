// App.js
import React, { useState } from "react";
import Login from "./components/Login";
import FlashCard from "./components/Flashcards";
import "./index.css";

const API_URL = window.location.href.includes("localhost")
  ? "http://localhost:9999"
  : "https://api.phillipsgary.com";

const App = () => {
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );
  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  );

  const [isLoggedIn, setIsLoggedIn] = useState(
    username.length && password.length
  );
  const [words, setWords] = useState([]);

  const preprocessWords = (words) => {
    const raw = JSON.parse(words).filter((word) => word && word.Word !== "");
    return raw
      .map((word) => {
        return {
          key: word.ID,
          id: word.ID,
          word: word.Word,
          pinyin: word.Pinyin,
          definition: word.Definition,
          audioPath: word.AudioPath,
          img: word.Img,
          userID: word.UserID,
          easiness: word.Easiness,
          interval: word.Interval,
          repeats: word.Repeats,
          duedate: Date(word.DueDate),
        };
      })
      .sort((a, b) => a.duedate - b.duedate);
  };

  const handleLogin = (username, password) => {
    setIsLoggedIn(true);
    setUsername(username);
    setPassword(password);
    fetch(`${API_URL}/users/word`, {
      method: "GET",
      mode: "cors",
      // credentials: "include",
      referrerPolicy: "strict-origin-when-cross-origin",

      headers: {
        Email: username,
        Password: password,
        // "Content-Type": "application/json",
      },
    })
      .then((res) => res.text())
      .then((res) => setWords(preprocessWords(res)))
      .catch((err) => alert(err))
      .finally(() => {
        console.log("Saved creds");
        localStorage.setItem("username", username);
        localStorage.setItem("password", password);
      });
  };
  /*
  {
      key: 1,
      ID: 1,
      CreatedAt: "2023-05-12T12:33:44.019511-07:00",
      UpdatedAt: "2023-05-23T19:50:10.078737-07:00",
      DeletedAt: null,
      Language: "",
      Pinyin: "wang",
      Word: "\u738b",
      Definition: "",
      AudioPath: "",
      Img: "string",
      UserID: 1,
      User: {
        ID: 0,
        CreatedAt: "0001-01-01T00:00:00Z",
        UpdatedAt: "0001-01-01T00:00:00Z",
        DeletedAt: null,
        email: "",
        password: "",
      },
      Easiness: 0,
      Interval: 0,
      Repeats: 0,
      DueDate: "0001-01-01T00:00:00Z",
    },
  */

  return (
    <div className="container mx-auto p-4">
      {" "}
      {isLoggedIn && words.length ? (
        <FlashCard flashCards={words} username={username} password={password} />
      ) : (
        <Login onLogin={handleLogin} />
      )}{" "}
    </div>
  );
};

export default App;
