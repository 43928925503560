import React, { useState } from "react";

import "./Pinyinlookup.css";

const API_URL = window.location.href.includes("localhost")
  ? "http://localhost:9999"
  : "https://languagelearning-crud-service-fxhtcex3zq-wl.a.run.app";

function addWord(username, password, raw) {
  fetch(`${API_URL}/words/ingest`, {
    method: "PUT",
    mode: "cors",
    headers: {
      Email: username,
      Password: password,
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ raw, language: "Chinese" }),
  })
    .then((parsedResults) =>
      alert(`Word added! ${JSON.stringify(parsedResults)}`)
    )
    .catch((e) => alert(e));
}

const PinyinDisplay = ({ results, credentials }) => {
  if (!results.tokens) {
    return <div>No results</div>;
  }
  return (
    <div>
      {results.tokens.map((token, index) => (
        <span key={index}>
          <span style={{ fontWeight: "bold" }}>{token.text}</span>
          <span style={{ fontStyle: "italic", marginLeft: "4px" }}>
            ({token.traditional} / {token.simplified}){" "}
            <button
              onClick={() =>
                addWord(credentials.username, credentials.password, token.text)
              }
            >
              Add
            </button>
          </span>
          <br />
          <ul style={{ listStyleType: "square", marginLeft: "20px" }}>
            {token.matches.map((match, matchIndex) => (
              <li key={matchIndex}>
                <strong>Pinyin:</strong> {match.pinyinPretty} <br />
                <strong>English:</strong> {match.english}
              </li>
            ))}
          </ul>
        </span>
      ))}
    </div>
  );
};

const PinyinLookup = ({ onSubmit }) => {
  const [text, setText] = useState("");

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(text);
  };

  return (
    <form className="input-container" onSubmit={handleSubmit}>
      <input
        className="text-field"
        type="text"
        value={text}
        onChange={handleChange}
      />{" "}
      <input type="submit" className="save-button" value="Submit" />
    </form>
  );
};

const PinyinLookupComponent = ({ credentials }) => {
  const [results, setResults] = useState({});
  const [hasResults, setHasResults] = useState(false);

  const handleFormSubmit = (text) => {
    let raw = "";
    const chineseRegex = /[\u4E00-\u9FFF\u3400-\u4DBF]+/g;
    const result = text.match(chineseRegex);
    if (result) {
      raw = result.join("");
    } else {
      alert("No Chinese characters found");
      return;
    }
    fetch(`${API_URL}/words/hydrate`, {
      method: "POST",
      mode: "cors",
      headers: {
        Email: credentials.username,
        Password: credentials.password,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ raw, language: "Chinese" }),
    })
      .then((res) => res.json())
      .then((parsedResults) => setResults(parsedResults))
      .catch((e) => alert(e))
      .finally(() => setHasResults(true));
  };

  return (
    <div>
      <PinyinLookup onSubmit={handleFormSubmit} />{" "}
      {hasResults && (
        <PinyinDisplay credentials={credentials} results={results} />
      )}
    </div>
  );
};

export default PinyinLookupComponent;
