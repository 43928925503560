// Login.js
import React, { useState } from "react";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );
  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  );

  const handleLogin = (e) => {
    e.preventDefault();
    // Perform validation and authentication here
    if (username && password) {
      onLogin(username, password);
    }
  };

  return (
    <div>
      <h1>登錄頁面</h1>
      <h2>Login Page</h2>
      <form onSubmit={handleLogin}>
        <label>
          Username:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        <br />
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <br />
        <button type="submit">Login</button>
        <button
          className="signout-btn"
          onClick={() => {
            localStorage.clear();
          }}
        >
          Logout
        </button>
      </form>
    </div>
  );
};

export default Login;
