import React, { useState } from "react";
import PinyinLookupComponent from "./Pinyinlookup";
import PhotoUploader from "./Capture";

import "./Flashcards.css";

const API_URL = window.location.href.includes("localhost")
  ? "http://localhost:9999"
  : "https://api.phillipsgary.com";

function submitPractice(username, password, card, confidence, callback) {
  // const {} = card;
  fetch(`${API_URL}/users/word/practice`, {
    method: "PUT",
    mode: "cors",
    headers: {
      Email: username,
      Password: password,
    },
    body: JSON.stringify({
      id: card.id,
      confidence,
      timestamp: Date.now(),
    }),
  })
    .catch((err) => alert(err))
    .finally(() => callback(card));
}

const AudioPlayer = ({ audioUrl }) => {
  return (
    <div>
      <audio controls>
        <source src={audioUrl} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

const Card = ({ word, credentials, reviewed }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);

  const handleClick = () => {
    if (!isAnswered) {
      submitPractice(
        credentials.username,
        credentials.password,
        word,
        5.0,
        console.log
      );
      setIsAnswered(true);
    }
    setIsOpen(!isOpen);
  };
  return (
    <div
      className={`element-card ${isOpen ? "open" : ""}`}
      onClick={handleClick}
    >
      <div className="front-facing">
        <h2>{word.word}</h2>
        {word.img && <img src={word.img} alt={word.word} />}
      </div>
      <div className="back-facing" style={{ opacity: isOpen ? 1 : 0 }}>
        <p className="answer">
          <span className="title">
            {word.word} / {word.pinyin}
          </span>
          <span className="sub-title">{word.definition}</span>
        </p>
        <AudioPlayer audioUrl={word.audioPath} />
        <button
          className="btn-hover color"
          onClick={() =>
            submitPractice(
              credentials.username,
              credentials.password,
              word,
              10.0,
              reviewed
            )
          }
        >
          Show less
        </button>
      </div>
    </div>
  );
};

function superMemo2(word) {
  const minInterval = 1; // Minimum interval in days
  const easinessFactor = word.Easiness;
  const interval = word.Interval;
  const repeats = word.Repeats;

  const newEasinessFactor =
    easinessFactor +
    (0.1 - (5 - word.Repeats) * (0.08 + (5 - word.Repeats) * 0.02));

  const newInterval =
    repeats === 0
      ? minInterval
      : repeats === 1
      ? 6
      : Math.round(interval * newEasinessFactor);

  const newDueDate = new Date();
  newDueDate.setDate(newDueDate.getDate() + newInterval);

  return {
    ...word,
    Easiness: newEasinessFactor,
    Interval: newInterval,
    Repeats: repeats + 1,
    DueDate: newDueDate,
  };
}

// Custom sorting function using the SuperMemo 2 algorithm
function sortBySuperMemo2(a, b) {
  const nextCardA = superMemo2(a);
  const nextCardB = superMemo2(b);
  return nextCardA.DueDate - nextCardB.DueDate;
}

const FlashCard = ({ flashCards, username, password }) => {
  const [reviewed, setReviewed] = useState(new Set());
  const [cards, setCards] = useState(flashCards);

  const clickedCard = (card) => {
    if (card && card.id) {
      setReviewed(reviewed.add(card.id));
      setCards(cards.filter((x) => !reviewed.has(x.id)));
    }
  };

  return (
    <div>
      <PhotoUploader credentials={{ username, password }} />;
      <PinyinLookupComponent credentials={{ username, password }} />
      <div className="container">
        {cards.sort(sortBySuperMemo2).map((card) => (
          <Card
            key={card.id}
            credentials={{ username, password }}
            reviewed={clickedCard}
            word={card}
          />
        ))}
      </div>
    </div>
  );
};

export default FlashCard;
