import React, { useState, useRef } from "react";

const API_URL = window.location.href.includes("localhost")
  ? "http://localhost:9999"
  : "https://api.phillipsgary.com";

const PhotoUploader = ({ credentials }) => {
  const { username, password } = credentials;
  const [selectedFile, setSelectedFile] = useState(null);
  const webcamRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const capturePhoto = () => {
    const webcam = webcamRef.current;
    if (webcam) {
      const photo = webcam.getScreenshot();
      const blob = dataURItoBlob(photo);
      setSelectedFile(blob);
    }
  };

  const handleUpload = (username, password) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("photo", selectedFile);
      if (!(username && password)) {
        alert("Please login first");
      }
      fetch(`${API_URL}/words/upload`, {
        method: "POST",
        mode: "cors",
        referrerPolicy: "strict-origin-when-cross-origin",
        headers: {
          email: username,
          password,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response from the server if needed
          console.log(data);
        })
        .catch((error) => {
          // Handle error if any
          console.error("Error uploading photo:", error);
        });
    }
  };

  // Function to convert dataURI to Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  };

  return (
    <div>
      <h2>Photo Uploader</h2>
      <input type="file" onChange={handleFileChange} />
      <br />
      {selectedFile ? (
        <div>
          <img
            src={URL.createObjectURL(selectedFile)}
            alt="Uploaded Preview"
            style={{ width: "200px", height: "200px" }}
          />
          <br />
          <button
            onClick={() => {
              debugger;
              handleUpload(username, password);
            }}
          >
            Upload
          </button>
        </div>
      ) : (
        <div>
          <video
            ref={webcamRef}
            style={{ width: "200px", height: "200px" }}
            autoPlay
            playsInline
          />
          <br />
          <button onClick={capturePhoto}>Capture Photo</button>
        </div>
      )}
    </div>
  );
};

export default PhotoUploader;
